import React from "react"
import { Nav } from "react-bootstrap"

const ListLink = props => <Nav.Link href={props.to}>{props.children}</Nav.Link>

const NavigationBar = class extends React.Component {
  render() {
    return (
      <header className="header-global">
        <nav
          id="navbar-main"
          className="navbar navbar-main navbar-expand-lg navbar-dark navbar-theme-primary py-lg-2 px-lg-6"
        >
          <div className="container">
            <a className="navbar-brand" href="/">
              <img
                className="navbar-brand-dark"
                src="/tb_test-01.svg"
                alt="Logo light"
              ></img>
            </a>
            <div className="navbar-collapse collapse" id="navbar_global">
              <div className="navbar-collapse-header">
                <div className="row">
                  <div className="col-6 collapse-brand">
                    <a href="/">
                      <img src="/tb_test-01.svg" alt="Menu logo" />
                    </a>
                  </div>
                </div>
              </div>
              <ul className="navbar-nav navbar-nav-hover ml-3 ml-auto">
                <li className="nav-item">
                  <ListLink to="/blog/" className="nav-link">
                    Blog
                  </ListLink>
                </li>
                <li className="nav-item">
                  <ListLink to="/about/" className="nav-link">
                    About
                  </ListLink>
                </li>
                <li className="nav-item">
                  <ListLink to="/contact/" className="nav-link">
                    Contact
                  </ListLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    )
  }
}

export default NavigationBar
