import React from "react"
import Layout from "../components/BlogPost/Layout"
import { graphql } from "gatsby"
import Footer from "../components/Footer"
import { FaTwitter, FaFacebook, FaLinkedin } from "react-icons/fa"
import {
  FaPercent,
  FaHome,
  FaBuilding,
  FaDollarSign,
  FaPiggyBank,
} from "react-icons/fa"

export default class PostTemplate extends React.Component {
  render() {
    const { data, pageContext } = this.props
    const { slug } = pageContext
    const postNode = data.allAirtable.edges[0].node
    const post = postNode.data
    if (!post.id) {
      post.id = slug
    }
    return (
      <Layout>
        <main>
          <div>
            <br></br>
            <br></br>
          </div>
          <div className="section section-md pt-15">
            <div className="container ">
              <div className="row justify-content-between">
                <div className="col-lg-9 pr-lg-4">
                  <article>
                    <header>
                      <div className="row">
                        <div className="col-12 col-lg-2">
                          <div className="post-group d-none d-lg-flex flex-column align-items-center">
                            <a href="/" className="text-center">
                              <img
                                className="avatar-lg avatar-sm-lg img-fluid rounded-circle mb-2"
                                src="/profile-image-4.jpg"
                                alt="avatar"
                              ></img>
                              <div className="font-small text-center">
                                <span className="text-gray">By</span>{" "}
                                {post.Author[0].data.Name}
                              </div>
                            </a>
                            <a
                              className="btn btn-sm btn-secondary mt-2 mb-4 animate-up-1"
                              href="/"
                            >
                              <span className="fas fa-user-plus mr-1 d-none d-xl-inline"></span>{" "}
                              Follow
                            </a>
                            <p className="font-small font-weight-light text-center mb-2">
                              Published:{" "}
                              <time dateTime="2009-10-09"> {post.Date}</time>
                            </p>
                          </div>
                          <div className="d-flex align-items-center d-lg-none mb-4">
                            <a
                              href="./profile.html"
                              className="font-small font-weight-bold mr-2"
                            >
                              <img
                                className="avatar-sm img-fluid rounded-circle mr-2"
                                src="/profile-image-4.jpg"
                                alt="avatar"
                              ></img>
                              Zoltan Szogyenyi
                            </a>
                            <span className="ml-1 font-weight-light font-small">
                              9h ago
                            </span>
                          </div>
                        </div>
                        <div className="col-11 col-lg-9">
                          <h1 className="mb-2 mb-lg-4">{post.Title}</h1>
                          <p className="d-lg-none font-small font-weight-light mb-4">
                            Published:
                            <time dateTime="2009-10-09">9th October, 2009</time>
                          </p>
                          <h2 className="text-gray font-medium font-weight-light">
                            {post.Subtitle}
                          </h2>
                        </div>
                        <div className="col-1">
                          <ul className="social-buttons list-style-none d-flex align-items-end flex-column">
                            <li className="mb-2">
                              <a
                                href="/"
                                target="_blank"
                                className="btn btn-lg btn-link btn-twitter text-twitter"
                              >
                                <i
                                  className="fab fa-twitter text-lg"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Share on Twitter"
                                  data-original-title="Share on Twitter"
                                >
                                  <FaTwitter />
                                </i>
                              </a>
                            </li>
                            <li className="mb-2">
                              <a
                                href="/"
                                target="_blank"
                                className="btn btn-lg btn-link btn-facebook text-facebook"
                              >
                                <i
                                  className="fab fa-facebook"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Share on Facebook"
                                  data-original-title="Share on Facebook"
                                >
                                  <FaFacebook />
                                </i>
                              </a>
                            </li>
                            <li className="mb-2">
                              <a
                                href="/"
                                target="_blank"
                                className="btn btn-lg btn-link btn-linkedin text-linkedin"
                              >
                                <i
                                  className="fab fa-linkedin"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Post on LinkedIn"
                                  data-original-title="Post on LinkedIn"
                                >
                                  <FaLinkedin />
                                </i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </header>
                    <div class="my-5">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: post.PostMarkdown.childMarkdownRemark.html,
                        }}
                      />
                    </div>
                  </article>
                </div>
                <aside className="col-12 col-lg-3 mt-3 mt-lg-0 d-none d-lg-block">
                  <ul className="card list-group list-group-flush shadow-soft border-soft p-3">
                    <li className="list-group-item border-0 py-2 d-flex align-items-center justify-content-between">
                      <a className="text-primary" href="/">
                        <i className="mr-2">
                          <FaDollarSign />
                        </i>
                        Investment
                      </a>
                      <span className="badge badge-soft text-uppercase">0</span>
                    </li>
                    <li className="list-group-item border-0 py-2 d-flex align-items-center justify-content-between">
                      <a className="text-primary" href="/">
                        <i className="mr-2">
                          <FaBuilding />
                        </i>
                        Property
                      </a>
                      <span className="badge badge-soft text-uppercase">0</span>
                    </li>
                    <li className="list-group-item border-0 py-2 d-flex align-items-center justify-content-between">
                      <a className="text-primary" href="/">
                        <i className="mr-2">
                          <FaHome />
                        </i>
                        Home loans
                      </a>
                      <span className="badge badge-soft text-uppercase">0</span>
                    </li>
                    <li className="list-group-item border-0 py-2 d-flex align-items-center justify-content-between">
                      <a className="text-primary" href="/">
                        <i className="mr-2">
                          <FaPiggyBank />
                        </i>
                        Planning
                      </a>
                      <span className="badge badge-soft text-uppercase">0</span>
                    </li>
                    <li className="list-group-item border-0 py-2 d-flex align-items-center justify-content-between">
                      <a className="text-primary" href="/">
                        <i className="mr-2">
                          <FaPercent />
                        </i>
                        Interest rates
                      </a>
                      <span className="badge badge-soft text-uppercase">0</span>
                    </li>
                  </ul>
                </aside>
              </div>
            </div>
          </div>
        </main>
        <Footer></Footer>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    allAirtable(filter: { data: { Slug: { eq: $slug } } }) {
      edges {
        node {
          data {
            Title
            Subtitle
            Slug
            Date
            Author {
              data {
                Name
              }
            }
            PostMarkdown {
              childMarkdownRemark {
                html
                timeToRead
              }
            }
            Image {
              url
            }
          }
        }
      }
    }
  }
`

// {
/* <Layout>
        <Container>
          <Row>
            <div className="col-lg-8">
              <h1 className="mt-4">{post.Title}</h1>

              <p className="lead">
                by&nbsp;
                <a href="/#">{post.Author[0].data.Name}</a>
              </p>
              <hr />
              <p>Posted on {post.Date}</p>
              <hr />
              <img
                className="img-fluid rounded"
                src={post.Image[0].url}
                alt=""
              ></img>
              <hr />
              <div
                dangerouslySetInnerHTML={{
                  __html: post.PostMarkdown.childMarkdownRemark.html,
                }}
              />
            </div>
            <div className="col-md-4">
              <div className="card my-4">
                <h5 className="card-header">Categories</h5>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6">
                      <ul className="list-unstyled mb-0">
                        <li>
                          <a href="/#">Web Design</a>
                        </li>
                        <li>
                          <a href="/#">HTML</a>
                        </li>
                        <li>
                          <a href="/#">Freebies</a>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6">
                      <ul className="list-unstyled mb-0">
                        <li>
                          <a href="/#">JavaScript</a>
                        </li>
                        <li>
                          <a href="/#">CSS</a>
                        </li>
                        <li>
                          <a href="/#">Tutorials</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container> */
// }

// {/* <div>
//   <Helmet>
//     <title>{`${post.title} | ${config.siteTitle}`}</title>
//   </Helmet>
//   <div>
//     <h1 className="title">{post.title}</h1>
//     <div className="date">
//       <span role="img" aria-label="calendar">
//         📅
//       </span>
//
//     </div>
//     <div
//       style={{
//         backgroundSize: "100%",
//         backgroundPosition: "center",
//         width: "100%",
//         height: "15rem",
//       }}
//     ></div>
//     <div
//       dangerouslySetInnerHTML={{
//         __html: post.PostMarkdown.childMarkdownRemark.html,
//       }}
//     />
//   </div>
// </div> */
